import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import firebase from "firebase";

Vue.config.productionTip = false;
firebase.initializeApp({
  apiKey: "AIzaSyA8Ta3zA9ycnwHjsclfzV14XJGo2vQbpZg",
  authDomain: "claim--ant.firebaseapp.com",
  projectId: "claim--ant",
  storageBucket: "claim--ant.appspot.com",
  messagingSenderId: "84423816030",
  appId: "1:84423816030:web:f0aac4b720016b295215cf",
  measurementId: "G-NHCRVVRB71",
});
//https://firebase.google.com/docs/auth/web/anonymous-auth
//https://github.com/firebase/snippets-web/blob/4b496235d2db433354ebb8084d59eba1263769a6/auth/link-multiple-accounts.js#L55-L61
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      // if (user === null) {
      //   this.$store.dispatch("autoLogin", user);
      // } else {
      console.log("user onAuthstateChange", user);
      console.log("state onAuthstateChange", store.state);
      // console.log(user.uid);
      this.$store.dispatch("autoLogin", user);
      // }
    });
  },
}).$mount("#app");
