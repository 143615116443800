<template>
  <v-app>
    <v-app-bar
      app
      style="border-bottom: 1px solid #b97c00"
      v-if="userIsAuthenticated"
    >
      <!-- <v-app-bar app color="#1de9b6"> -->
      <div class="d-flex align-center">
        <!-- <div class="font-weight-black" style="color: #ffab00">claim•ant</div> -->
        <div class="font-weight-black" style="color: #ffab00">OneClaim</div>
      </div>
      <v-spacer></v-spacer>
      <div v-if="userIsAuthenticated" class="black--text font-weight-black">
        {{ user.email }}
      </div>
      <v-btn text v-if="userIsAuthenticated" @click="onLogout">
        <v-icon class="hidden-xs-only">mdi-exit-to-app</v-icon>
        <span class="mr-2 red--text">LOGOUT</span>
      </v-btn>
      <span>&#128028;</span>
    </v-app-bar>

    <v-main>
      <!-- <HelloWorld /> -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";
import firebase from "firebase";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },

  data: () => ({
    //
    claimant: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userIsAuthenticated() {
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      );
    },
  },
  methods: {
    onLogout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/");
        });
      // let user = this.$store.getters.user;
      // console.log("user from app.vue", user);
    },
  },
  watch: {
    user(value) {
      if (value === null && value === undefined) {
        this.$store.dispatch("autoLogin", value);
        this.$router.push("/");
      }
    },
  },
  created() {
    // let user = this.$store.getters.user;
    // if (user !== null && user !== undefined) {
    //   firebase
    //     .firestore()
    //     .collection("claimants")
    //     .doc(user.id)
    //     .get()
    //     .then((claimant) => {
    //       this.claimant = claimant.data();
    //     });
    // }
  },
};
</script>
