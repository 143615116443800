<template>
  <div class="ma-auto">
    <v-row class="mt-2">
      <v-spacer></v-spacer>
      <v-btn
        small
        rounded
        @click="expand2 = !expand2"
        class="mt-2 mr-5"
        color="white"
        light
      >
        LOG IN
      </v-btn>
      <v-btn
        small
        rounded
        class="mt-2 mr-5"
        color="blue"
        light
        v-if="!user"
        @click="expand = !expand"
      >
        SIGN UP
      </v-btn>
    </v-row>
    <p
      class="display-2 text-center font-weight-bold ma-auto black--text"
      style="background-color: #ffc755"
    >
      <!-- claim<span>&#128028;</span>ant -->
      OneClaim
    </p>
    <h1 class="text-center mt-4" style="font-size: 28px">
      HANDLE YOUR OWN INSURANCE CLAIM
    </h1>
    <v-row class="text-center">
      <v-col cols="12">
        <!-- <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        /> -->
      </v-col>
      <v-flex xs12 sm12 md2 lg2>
        <v-col>
          <div class="text-center font-weight-bold ma-auto h3">
            ESTIMATED VALUE:
          </div>
          <v-expand-x-transition>
            <div class="display-1 text-center">
              <!-- <div class="display-1 text-center" v-if="showValue"> -->
              <!-- <div class="display-1 text-center" v-if="claimValue"> -->
              {{
                claimValue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              }}
            </div>
          </v-expand-x-transition>
          <v-btn
            rounded
            color="#1de9b6"
            @click="valueClaim"
            class="ma-auto"
            v-if="injuries.length && this.totalInjuryCost"
            >VALUE MY CLAIM</v-btn
          >
          <!-- doneAddingTotalCost = false; doneListingInjuries = false; -->
          <v-expand-x-transition>
            <v-btn
              rounded
              color="blue"
              class="ma-auto mt-1"
              v-if="claimValue"
              @click="contactDialog = true"
              >GET HELP</v-btn
            >
          </v-expand-x-transition>
          <v-dialog v-model="showValueDialog" max-width="800">
            <v-card
              class="mx-auto"
              outlined
              rounded
              text
              style="border: 1px #1e88e5 solid"
              max-width="800"
            >
              <h3 class="text-center font-weight-bold ma-auto">
                ESTIMATED VALUE:
              </h3>
              <v-expand-x-transition>
                <div class="display-1 text-center">
                  <!-- <div class="display-1 text-center" v-if="showValue"> -->
                  <!-- <div class="display-1 text-center" v-if="claimValue"> -->
                  {{
                    claimValue.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  }}
                </div>
              </v-expand-x-transition>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  color="blue"
                  class="ma-auto"
                  v-if="claimValue"
                  @click="contactDialog = true"
                  max-width="300px"
                  >GET HELP</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="expand2" max-width="600px">
            <!-- <v-expand-transition> -->
            <v-form @submit.prevent="login">
              <v-card
                class="mx-auto"
                outlined
                rounded
                text
                style="border: 1px #1e88e5 solid"
              >
                <!-- <v-card
                v-show="expand2"
                class="mx-auto"
                outlined
                rounded
                text
                style="border: 1px #1E88E5 solid"
              > -->
                <v-text-field
                  class="ma-3"
                  label="EMAIL"
                  color="#1E88E5"
                  outlined
                  dense
                  :rules="[rules.email]"
                  validate-on-blur
                  v-model="loginEmail"
                ></v-text-field>
                <v-text-field
                  class="ma-3"
                  label="PASSWORD"
                  outlined
                  color="#1E88E5"
                  type="password"
                  dense
                  v-model="logInPassword"
                  @keydown.enter="login"
                ></v-text-field>

                <v-card-actions>
                  <v-btn
                    outlined
                    rounded
                    color="green"
                    @click="login"
                    :loading="loginLoading"
                    class="ma-2"
                    >LOG IN</v-btn
                  >
                </v-card-actions>
                <v-expand-transition>
                  <div v-if="loginMessage">{{ loginMessage }}</div>
                </v-expand-transition>
              </v-card>
            </v-form>
            <!-- </v-expand-transition> -->
          </v-dialog>
          <v-dialog v-model="contactDialog" max-width="800">
            <v-card
              class="mx-auto"
              outlined
              rounded
              text
              style="border: 1px #1e88e5 solid"
              ><v-btn
                @click="contactDialog = false"
                top
                left
                outlined
                rounded
                color="red"
                class="ma-1"
                small
              >
                CLOSE
              </v-btn>
              <v-subheader class="ma-auto"
                >Please input your email and a brief description of what
                happened and we will contact you regarding your
                claim</v-subheader
              >
              <v-text-field
                class="ma-3"
                label="YOUR FULL NAME"
                color="#1E88E5"
                outlined
                dense
                validate-on-blur
                v-model="fullName"
              ></v-text-field>
              <v-text-field
                class="ma-3"
                label="EMAIL"
                color="#1E88E5"
                outlined
                dense
                :rules="[rules.email]"
                validate-on-blur
                v-model="contactEmail"
              ></v-text-field>
              <v-textarea
                class="ma-3"
                label="What Happened?"
                outlined
                color="#1E88E5"
                dense
                v-model="whatHappened"
              ></v-textarea>
              <v-checkbox
                v-model="agreement"
                color="light-green accent-1"
                label="I HAVE REVIEWED AND AGREE TO THE"
                class="ma-3"
              >
              </v-checkbox>
              <div @click="dialog = true" style="text-decoration: underline">
                Terms of Service & Privacy Policy
              </div>
              <v-dialog v-model="dialog" absolute max-width="400" persistent>
                <v-card>
                  <v-card-title>Legal</v-card-title>
                  <v-card-text class="text-justify">
                    I FULLY UNDERSTAND THAT THERE ARE STRICT TIME LIMITS IMPOSED
                    BY EACH STATE DIFFERENTLY AS TO THE AMOUNT OF TIME I HAVE
                    BEFORE I LOSE MY RIGHT TO SEEK RELIEF IN THE COURTS FOR MY
                    INJURY. THERE ARE FURTHER LIMITATIONS IF MY CLAIM IS AGAINST
                    A GOVERNMENT ENTITY. I HAVE NO INTENTION TO USE CLAIMANT.IO
                    OR ITS AFFILIATED PLATFORMS AND SUBSIDIARIES AS MY LEGAL
                    REPRESENTATIVE OR ADVISER. CLAIMANT.IO IS NOT MY LAWYER AND
                    HAS NOT PUT ITSELF OUT TO BE SUCH. I HAVE DECIDED TO HANDLE
                    MY OWN DAMAGE CLAIM, OR I HAVE DESIGNATED MY REPRESENTATIVE
                    TO DO SO USING THE SERVICES PROVIDED BY CLAIMANT.IO ON MY
                    OWN VOLITION. I CERTIFY THAT I AM AN ADULT OF SOUND MIND AND
                    THAT ALL THE INFORMAITON I PROVIDE, IN ANY WAY, TO
                    CLAIMANT.IO AND ITS AFFILIATES IS TRUE AND CORRECT TO THE
                    BEST OF MY KNOWLEDGE AND ANY FALSHOODS OR INACCURACIES ARE
                    MY SOLE RESPONSIBILITY AND NOT THE RESPONSIBILITY OF
                    CALIMANT.IO. I UNDERSTAND THAT CLAIMANT.IO IS MERELY A TOOL
                    WHICH IS COMPLETELY IN MY CONTROL PURPOSED TO COMMUNICATE MY
                    CLAIM TO INDIVIDUALS, ORGANIZATION S AND INSURANCE COMPANIES
                    WHO MAY BE RESPONSIBLE FOR THE DAMAGES I CLAIM. I CERTIFY
                    THAT I AM AN ADULT OF SOUND MIND AND ABILITY TO UNDERSTAND
                    THE COMPLEXITIES OF MY OWN CLAIM FOR DAMAGES AND SO TAKE
                    FULL RESPONSIBILITY FOR THE OUTCOME OF MY ACTIONS WHETHER BY
                    WAY OF MY USE OF CLAIMANT.IO OR ANY OF ITS SERVICES OR BY
                    WAY OF ACTIONS TAKEN OUTSIDE OF THE USE OF ANY OF
                    CLAIMANT.IO'S TOOLS AND SERVICES. I AGREE TO HOLD
                    CLAIMANT.IO HARMLESS FOR ANY DISAGREEABLE RESULT STEMMING
                    FROM ANY USE OF CLAIMANT.IO OR LACK THERE OF. I AGREE TO
                    INDEMNIFY CLAIMANT.IO FOR ANY DAMAGES INCURRED BY ANY PARTY
                    STEMMING FROM MY CLAIM OR THE USE OF CLAIMANT.IO FOR ANY
                    PURPOSE. I UNDERSTAND THAT CALIMANT.IO HAS MADE NO
                    ASSURANCES OR PROMISES TO FULLFILL ANY EXPECTATIONS I MAY
                    HAVE FROM THE USE OF CLAIMANT.IO AND I CERTIFY THAT
                    CLAIMANT.IO IS NOT BEING COMPENSATED BASED ON MY RECOVERY
                    FROM MY CLAIM NOR IS ANY COMPENSATION BASED ON WHETHER MY
                    CLAIM IS SUCCESSFUL OR NOT BUT CLAIMANT.IO IS BEING
                    COMPENSATED FOR MY USE OF THE CLAIMANT.IO PLATFORM
                    REGARDLESS OF THE OUTCOME OF MY CLAIM. I FURTHER UNDERTAND
                    THAT I HAVE AND SHOULD EXERCISE THE RIGHT TO HAVE
                    REPRESENTATION AND MAY HAVE REPRESENTATION WHILE USING THIS
                    TOOL. I ALSO UNDERSTAND THAT CLAIMANT.IO MAY SUGGEST AN
                    ATTORNEY TO ME AND IN DOING SO I RELEASE CLAIMANT.IO FROM
                    ANY LIABILITY THAT MAY STEM FROM MY RETENTION OF ANY
                    ATTORNEY SUGGESTED BY CLAIMANT.IO AND I UNDERSTAND THAT
                    CLAIMANT.IO IS IN NO WAY RESPONSIBLE FOR THE ACTIONS OF ANY
                    OF THE SERVICE PROVIDERS THAT UTILIZE ITS PLATFORM INCLUDING
                    ATTORNEYS AND THEIR STAFF. I FURTHER UNDERSTAND THAT IT IS
                    MY RESPONSIBILITY TO JUDGE THE CHARACTER AND MERITS OF ANY
                    ATTORNEY I SELECT AND IT IS THE ATTORNEY'S DUTY TO DO THEIR
                    DUE DILIGENCE WHEN DECIDING TO TAKE MY CLAIM AND MYSELF ON
                    AS A CLIENT. THAT DECISION IS MADE BY ME AND THE ATTORNEY
                    WITHOUT ANY SAY ON THE PART OF CLAIMANT.IO AND THAT DECISION
                    IS MADE PRIOR TO MY SIGNING ANY RETAINER AGREEMENT WITH ANY
                    ATTORNEY. I UNDERSTAND THAT ONCE I HAVE AGREED TO A
                    SETTLEMENT AND RELEASED THE RESPONSIBLE PARTY FROM ALL
                    LIABILITY, I MAY NO LONGER SEEK ANY DAMAGES FOR THE CLAIM AS
                    TO THAT RESPONSIBLE PARTY UNLESS AND UNTIL I HAVE A SEPARATE
                    CLAIM WITH DIFFERENT FACTS AND CIRCUMSTANCES......
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn text @click="(agreement = false), (dialog = false)"
                      >No</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      class="white--text"
                      color="light-green accent-1 accent-4"
                      @click="(agreement = true), (dialog = false)"
                      >Yes</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- <div @click="dialog = true"></div> -->
              <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  color="green"
                  @click="sendContactEmail"
                  class="ma-2"
                  :disabled="!agreement || !fullName"
                  :loading="isLoading"
                  >SUBMIT</v-btn
                >
              </v-card-actions>
              <v-expand-transition>
                <div v-if="loginMessage">{{ loginMessage }}</div>
              </v-expand-transition>
            </v-card>
          </v-dialog>
        </v-col>
      </v-flex>

      <v-flex xs12 sm12 md8 lg8>
        <v-col>
          <v-expand-transition v-if="!expand">
            <v-card
              class="mx-auto pa-3"
              outlined
              rounded
              text
              style="border: 1px #1e88e5 solid"
            >
              <h2 class="font-weight-light" style="color: #1e88e5">
                HOW MUCH IS YOUR CLAIM WORTH?
              </h2>
              <h3 class="font-weight-light">Lets find out together</h3>
              <!-- PHYSICAL INJURIES ?-->
              <v-card flat>
                <h2 style="color: #1e88e5">
                  Were you physically injured in an accident?
                </h2>
                <v-card-text class="ma-0 pa-0">
                  <v-container fluid class="pa-0">
                    <v-row class="pa-0">
                      <v-col></v-col>
                      <v-radio-group v-model="personalInjury" row class="pa-0">
                        <!-- <v-col cols="12" sm="4" md="4"> -->
                        <v-radio
                          label="Yes"
                          class="ma-3"
                          :value="true"
                          @click="listingInjuries = true"
                        ></v-radio>
                        <v-col></v-col>
                        <v-radio
                          label="No"
                          class="ma-3"
                          :value="false"
                        ></v-radio>
                        <!-- </v-col> -->
                      </v-radio-group>
                      <v-col></v-col>
                    </v-row>
                  </v-container>
                  <h3
                    v-if="injuries.length"
                    style="color: #1e88e5"
                    class="mb-3"
                  >
                    injury list
                  </h3>
                  <div v-for="(injury, index) in injuries" :key="index">
                    <v-card
                      flat
                      shaped
                      style="border: 2px solid #1e88e5"
                      class="text-align center ma-auto"
                      max-width="80%"
                      v-if="injuries.length && !listingInjuries"
                    >
                      <v-spacer></v-spacer>
                      <v-card-text class="ma-auto">
                        <v-row>
                          <!-- <div class="ma-auto white--text"> -->
                          <div class="ma-auto">
                            {{ index + 1 }}. {{ injury.text }}
                          </div>
                          <v-spacer></v-spacer>
                          <div class="ma-auto">
                            (Pain Level: {{ injury.painLevel }})
                          </div>
                          <v-spacer></v-spacer>
                          <!-- <div class="ma-auto">({{ injury.qualifier }})</div> -->
                          <!-- <v-spacer></v-spacer> -->
                          <!-- <div class="ma-auto">
                            (Cost:
                            {{
                              injury.cost.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }})
                          </div> -->
                          <v-btn
                            text
                            small
                            rounded
                            outlined
                            color="red"
                            @click="removeInjury(index)"
                            class="ma-auto"
                            >remove</v-btn
                          >
                        </v-row>
                      </v-card-text>
                      <v-spacer></v-spacer>
                    </v-card>
                  </div>

                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    color="green"
                    @click="listingInjuries = true"
                    class="ml-auto mr-auto mt-2"
                    v-if="injuries.length"
                    >ADD MORE INJURIES</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    @click="doneListingInjuries = true"
                    class="ml-auto mr-auto mt-2"
                    v-if="injuries.length"
                    >DONE ADDING INJURIES</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-text>
                <v-dialog v-model="listingInjuries" id="dialog" max-width="800">
                  <v-expand-transition>
                    <!-- <v-card flat v-if="personalInjury" class="ma-0 pa-0"> -->
                    <v-card
                      flat
                      v-if="personalInjury"
                      class="ma-0 pa-0"
                      style="border: 1px solid #1e88e5"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="doneInjuries"
                        top
                        left
                        outlined
                        rounded
                        color="red"
                        class="ma-1"
                        small
                      >
                        <!-- <v-icon>mdi-close</v-icon> -->
                        DONE
                      </v-btn>
                      <!-- <v-btn
                        top
                        right
                        fixed
                        rounded
                        outlined
                        color="#1E88E5"
                        @click="listingInjuries = false"
                        class="mr-4 mt-4"
                        :disabled="!injuries.length"
                        >DONE ADDING INJURIES</v-btn
                      > -->
                      <h4
                        style="color: #1e88e5; text-align: center"
                        class="ma-auto"
                      >
                        List Each Injury Here <br />
                        (one at a time)
                      </h4>
                      <v-card-text class="pa-0">
                        <v-container fluid class="pt-0 pb-3">
                          <v-col>
                            <v-col>
                              <!-- INJURY INPUT - NAME  -->
                              <v-row
                                class="pa-0 pt-1 ml-auto mr-auto"
                                style="max-width: 600px"
                              >
                                <v-text-field
                                  class="mt-1"
                                  label="Enter the type of injury"
                                  outlined
                                  color="#1E88E5"
                                  v-model="tempInjury"
                                  autofocus
                                  dense
                                  id="focusElement"
                                  hint="Ex: Emotional distress /  Lumbar Disc Herniation"
                                >
                                </v-text-field>
                              </v-row>
                              <!-- INJURY DETAILS  -->
                              <!-- HOW PAINFUL  -->
                              <v-expand-transition>
                                <!-- <v-row class="pa-0" v-if="tempInjury"> -->
                                <v-row class="pa-0 ma-0">
                                  <v-subheader
                                    class="mb-2 mt-1 ml-auto mr-auto"
                                    style="
                                      font-size: 15px;
                                      font-weight: 600;
                                      color: #1e88e5;
                                    "
                                    color="#1E88E5"
                                    >On a Scale of 1-10 How Painful Was This
                                    Injury?
                                  </v-subheader>
                                  <v-card-text class="pa-0">
                                    <v-card
                                      flat
                                      max-width="500px"
                                      class="ma-auto"
                                    >
                                      <v-slider
                                        v-model="tempPainLevel"
                                        color="#1E88E5"
                                        max="10"
                                        min="1"
                                        thumb-label="always"
                                        thumb-color="blue"
                                        :disabled="!tempInjury"
                                        class="pa-0 ma-0"
                                        dense
                                      ></v-slider>
                                    </v-card>
                                  </v-card-text>
                                </v-row>
                              </v-expand-transition>
                              <!-- INJUR PROGRESSION  -->
                              <v-expand-transition>
                                <!-- <v-row class="pa-0" v-if="tempInjury"> -->
                                <v-row class="pa-0 ma-0">
                                  <v-subheader
                                    style="
                                      font-size: 15px;
                                      font-weight: 600;
                                      color: #1e88e5;
                                    "
                                    class="ml-auto mr-auto"
                                    >How Has This Injury Progressed?
                                  </v-subheader>
                                  <v-card-text class="pa-0">
                                    <v-row class="pa-0">
                                      <v-spacer></v-spacer>
                                      <v-radio-group
                                        v-model="tempInjuryQualifier"
                                        :disabled="!tempInjury"
                                        class="pa-0"
                                        row
                                      >
                                        <v-radio
                                          value="0.0"
                                          label="Completely Healed"
                                        ></v-radio>
                                        <v-radio
                                          value="0.05"
                                          label="Getting Better"
                                        ></v-radio>
                                        <v-radio
                                          value="0.10"
                                          label="Remaining the Same"
                                        ></v-radio>
                                        <v-radio
                                          value="0.15"
                                          label="Getting Worse"
                                        ></v-radio>
                                      </v-radio-group>
                                      <v-spacer></v-spacer>
                                    </v-row>
                                  </v-card-text>
                                </v-row>
                              </v-expand-transition>
                              <!-- TREATMENT  -->
                              <v-expand-transition>
                                <!-- <v-row class="pa-0" v-if="tempInjury"> -->
                                <v-row class="pa-0 ma-0">
                                  <v-subheader
                                    style="
                                      font-size: 15px;
                                      font-weight: 600;
                                      color: #1e88e5;
                                    "
                                    class="ml-auto mr-auto"
                                  >
                                    Have You Received Any Treatment For This
                                    Injury?
                                  </v-subheader>
                                  <v-card-text class="pt-1 pb-0">
                                    <v-container fluid class="pa-0">
                                      <v-row class="pa-0">
                                        <!-- <v-col></v-col> -->
                                        <v-spacer></v-spacer>
                                        <v-radio-group
                                          v-model="tempInjuryTreatment"
                                          row
                                          :disabled="!tempInjury"
                                          class="ma-0 pa-0"
                                        >
                                          <!-- <v-col cols="12" sm="4" md="4"> -->
                                          <v-radio
                                            label="Yes"
                                            class="ma-3"
                                            :value="true"
                                          ></v-radio>
                                          <v-col></v-col>
                                          <v-radio
                                            label="No"
                                            class="ma-3"
                                            :value="false"
                                          ></v-radio>
                                        </v-radio-group>
                                        <!-- <v-col></v-col> -->
                                        <v-spacer></v-spacer>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      small
                                      rounded
                                      color="#1de9b6"
                                      @click="addInjury"
                                      class="pt-0 ma-auto"
                                      :disabled="!tempInjury"
                                      >ADD INJURY</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-row>
                              </v-expand-transition>
                              <!-- INJURY COST  -->
                              <!-- <v-expand-transition>
                                <v-row>
                                  <v-subheader
                                    style="
                                      font-size: 13px;
                                      font-weight: 600;
                                      color: #1E88E5;
                                    "
                                    class="ml-auto mr-auto"
                                  >
                                    Input The Total Amount Billed (to you AND/OR
                                    your health insurance) For This Injury?
                                  </v-subheader>
                                  <v-card-text class="pa-0">
                                    <v-container fluid>
                                      <v-row
                                        class="ma-auto"
                                        style="max-width: 600px"
                                      >
                                        <v-text-field
                                          class="pt-2"
                                          label="Enter Amount"
                                          outlined
                                          color="#1E88E5"
                                          v-model="tempInjuryCost"
                                          dense
                                          type="number"
                                          prefix="$"
                                          max-width="500px"
                                          :disabled="!tempInjury"
                                        >
                                        </v-text-field>
                                      </v-row>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        small
                                        rounded
                                        outlined
                                        color="#1E88E5"
                                        @click="addInjury"
                                        class="pt-0 ma-auto"
                                        :disabled="!tempInjury"
                                        >ADD INJURY</v-btn
                                      >
                                    </v-container>
                                  </v-card-text>
                                </v-row>
                              </v-expand-transition> -->
                              <div
                                v-for="(injury, index) in injuries"
                                :key="index"
                              >
                                <!-- INJURY LIST DISPLAY  -->
                                <v-card
                                  flat
                                  shaped
                                  style="border: 2px solid #1e88e5"
                                  class="text-align center ma-auto pb-2"
                                  max-width="500px"
                                >
                                  <v-spacer></v-spacer>
                                  <v-card-text class="ma-auto">
                                    <v-row>
                                      <div class="ma-auto font-weight-black">
                                        {{ index + 1 }}. {{ injury.text }}
                                      </div>
                                      <v-spacer></v-spacer>
                                      <div class="ma-auto">
                                        (Pain Level: {{ injury.painLevel }})
                                      </div>
                                      <v-spacer></v-spacer>

                                      <v-btn
                                        text
                                        small
                                        rounded
                                        outlined
                                        color="red"
                                        @click="removeInjury"
                                        class="ma-auto"
                                        >remove</v-btn
                                      >
                                    </v-row>
                                  </v-card-text>
                                  <v-spacer></v-spacer>
                                </v-card>
                              </div>
                            </v-col>
                          </v-col>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-expand-transition>
                </v-dialog>
              </v-card>
              <!-- <v-expand-transition>
                <v-card flat v-if="doneListingInjuries">
                  <v-card-text>
                    <h2 style="color: #1E88E5">
                      Have You Received Treatment for Any of Your Injuries?
                    </h2>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col></v-col>
                          <v-radio-group v-model="medicalDamages" row>
                            <v-radio
                              label="Yes"
                              class="ma-3"
                              :value="true"
                            ></v-radio>
                            <v-col></v-col>
                            <v-radio
                              label="No"
                              class="ma-3"
                              :value="false"
                            ></v-radio>
                          </v-radio-group>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
            
                  </v-card-text>
                </v-card>
              </v-expand-transition> -->
              <v-dialog
                v-model="doneListingInjuries"
                style="border: 1px #1e88e5 solid"
                max-width="800"
              >
                <v-card style="border: 1px #1e88e5 solid">
                  <v-btn
                    @click="doneListingInjuries = false"
                    top
                    left
                    outlined
                    rounded
                    color="red"
                    class="ma-1"
                    small
                  >
                    CLOSE
                  </v-btn>
                  <v-expand-transition>
                    <v-card
                      flat
                      v-if="!listingInjuries && this.injuries.length"
                    >
                      <v-card-text>
                        <h3
                          style="color: #1e88e5; text-align: center"
                          class="ma-auto"
                        >
                          What is the total amount billed to you OR your health
                          insurance for ALL of your treatment?
                        </h3>
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col></v-col>
                              <v-text-field
                                class="ma-3"
                                label="TOTAL BILLED"
                                outlined
                                color="#1E88E5"
                                type="number"
                                dense
                                v-model="totalInjuryCost"
                                hint="*Numbers Only*"
                                placeholder="$0.0"
                                autofocus
                              ></v-text-field>
                              <v-col></v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          small
                          rounded
                          color="#1de9b6"
                          @click="doneAddingTotalCost = true"
                        >
                          DONE
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-expand-transition>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="doneAddingTotalCost"
                style="border: 1px #1e88e5 solid"
                max-width="800"
              >
                <v-card style="border: 1px #1e88e5 solid">
                  <v-btn
                    @click="
                      doneAddingTotalCost = false;
                      doneListingInjuries = false;
                    "
                    top
                    left
                    outlined
                    rounded
                    color="red"
                    class="ma-1"
                    small
                  >
                    CLOSE
                  </v-btn>
                  <v-expand-transition>
                    <!-- <v-card flat v-if="totalInjuryCost"> -->
                    <v-card flat>
                      <v-card-text class="ma-0 pa-0">
                        <h3
                          style="color: #1e88e5; text-align: center"
                          class="ma-auto"
                        >
                          Was any of your property damaged in the incident?
                        </h3>
                        <v-card-text>
                          <v-container fluid>
                            <v-row class="pa-0">
                              <v-col></v-col>
                              <v-radio-group
                                v-model="propertyDamage"
                                row
                                class="pa-0"
                              >
                                <!-- <v-col cols="12" sm="4" md="4"> -->
                                <v-radio
                                  label="Yes"
                                  class="ma-3"
                                  :value="true"
                                ></v-radio>
                                <v-col></v-col>
                                <v-radio
                                  label="No"
                                  class="ma-3"
                                  :value="false"
                                  @click="
                                    doneAddingTotalCost = false;
                                    doneListingInjuries = false;
                                    valueClaim();
                                  "
                                ></v-radio>
                                <!-- <v-radio
                                  label="No"
                                  class="ma-3"
                                  :value="false"
                                  @click="valueClaim"
                                ></v-radio> -->
                                <!-- </v-col> -->
                              </v-radio-group>
                              <v-col></v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card-text>
                      <v-expand-transition>
                        <!-- <v-card flat v-if="propertyDamage"> -->
                        <v-card flat>
                          <v-card-text>
                            <h3
                              style="color: #1e88e5; text-align: center"
                              class="ma-auto"
                            >
                              What was the total cost of repairing or replacing
                              the damaged property?
                            </h3>
                            <v-card-text>
                              <v-container fluid>
                                <v-row>
                                  <v-col></v-col>

                                  <v-text-field
                                    class="ma-3"
                                    label="TOTAL PROPERTY DAMAGE COST"
                                    outlined
                                    color="#1E88E5"
                                    type="number"
                                    dense
                                    v-model="totalPropertyDamageCost"
                                    hint="*Numbers Only*"
                                    placeholder="$0.0"
                                    autofocus
                                    :disabled="!propertyDamage"
                                  ></v-text-field>
                                  <v-col></v-col>
                                </v-row>
                                <v-card-actions>
                                  <v-btn
                                    small
                                    rounded
                                    color="#1de9b6"
                                    @click="
                                      doneAddingTotalCost = false;
                                      doneListingInjuries = false;
                                      valueClaim();
                                    "
                                    class="ma-auto"
                                    >DONE</v-btn
                                  >
                                  <!-- <v-btn
                                    large
                                    rounded
                                    outlined
                                    color="#1de9b6"
                                    @click="valueClaim"
                                    class="ma-auto"
                                    v-if="injuries.length"
                                    >VALUE MY CLAIM</v-btn
                                  > -->
                                </v-card-actions>
                              </v-container>
                            </v-card-text>
                          </v-card-text>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </v-expand-transition>
                </v-card>
              </v-dialog>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn
                  small
                  rounded
                  outlined
                  color="green"
                  @click="listingInjuries = true"
                  class="ma-auto"
                  v-if="injuries.length"
                  >ADD MORE INJURIES</v-btn
                > -->
                <v-spacer></v-spacer>
                <!-- <v-btn
                  large
                  rounded
                  outlined
                  color="#1de9b6"
                  @click="doneListingInjuries = true"
                  class="ma-auto"
                  v-if="injuries.length"
                  >Done</v-btn
                > -->
                <!-- <v-btn
                  large
                  rounded
                  outlined
                  color="#1de9b6"
                  @click="valueClaim"
                  class="ma-auto"
                  v-if="injuries.length"
                  >VALUE MY CLAIM</v-btn
                > -->
              </v-card-actions>
            </v-card>
          </v-expand-transition>
          <v-expand-transition>
            <v-card
              v-if="expand"
              class="mx-auto"
              outlined
              rounded
              text
              style="border: 1px #1e88e5 solid"
            >
              <v-text-field
                class="ma-3"
                label="EMAIL"
                color="#1E88E5"
                outlined
                dense
                :rules="[rules.email]"
                validate-on-blur
                v-model="email"
              ></v-text-field>
              <v-text-field
                class="ma-3"
                label="PASSWORD"
                outlined
                color="#1E88E5"
                type="password"
                dense
                counter="6"
                v-model="password"
              ></v-text-field>
              <v-checkbox
                v-model="agreement"
                color="light-green accent-1"
                label="I HAVE REVIEWED AND AGREE TO THE"
                class="ma-3"
              >
              </v-checkbox>
              <div @click="dialog = true" style="text-decoration: underline">
                Terms of Service & Privacy Policy
              </div>
              <div @click="dialog = true"></div>
              <v-dialog v-model="dialog" absolute max-width="400" persistent>
                <v-card>
                  <v-card-title>Legal</v-card-title>
                  <v-card-text class="text-justify">
                    I FULLY UNDERSTAND THAT THERE ARE STRICT TIME LIMITS IMPOSED
                    BY EACH STATE DIFFERENTLY AS TO THE AMOUNT OF TIME I HAVE
                    BEFORE I LOSE MY RIGHT TO SEEK RELIEF IN THE COURTS FOR MY
                    INJURY. THERE ARE FURTHER LIMITATIONS IF MY CLAIM IS AGAINST
                    A GOVERNMENT ENTITY. I HAVE NO INTENTION TO USE CLAIMANT.IO
                    OR ITS AFFILIATED PLATFORMS AND SUBSIDIARIES AS MY LEGAL
                    REPRESENTATIVE OR ADVISER. CLAIMANT.IO IS NOT MY LAWYER AND
                    HAS NOT PUT ITSELF OUT TO BE SUCH. I HAVE DECIDED TO HANDLE
                    MY OWN DAMAGE CLAIM, OR I HAVE DESIGNATED MY REPRESENTATIVE
                    TO DO SO USING THE SERVICES PROVIDED BY CLAIMANT.IO ON MY
                    OWN VOLITION. I CERTIFY THAT I AM AN ADULT OF SOUND MIND AND
                    THAT ALL THE INFORMAITON I PROVIDE, IN ANY WAY, TO
                    CLAIMANT.IO AND ITS AFFILIATES IS TRUE AND CORRECT TO THE
                    BEST OF MY KNOWLEDGE AND ANY FALSHOODS OR INACCURACIES ARE
                    MY SOLE RESPONSIBILITY AND NOT THE RESPONSIBILITY OF
                    CALIMANT.IO. I UNDERSTAND THAT CLAIMANT.IO IS MERELY A TOOL
                    WHICH IS COMPLETELY IN MY CONTROL PURPOSED TO COMMUNICATE MY
                    CLAIM TO INDIVIDUALS, ORGANIZATION S AND INSURANCE COMPANIES
                    WHO MAY BE RESPONSIBLE FOR THE DAMAGES I CLAIM. I CERTIFY
                    THAT I AM AN ADULT OF SOUND MIND AND ABILITY TO UNDERSTAND
                    THE COMPLEXITIES OF MY OWN CLAIM FOR DAMAGES AND SO TAKE
                    FULL RESPONSIBILITY FOR THE OUTCOME OF MY ACTIONS WHETHER BY
                    WAY OF MY USE OF CLAIMANT.IO OR ANY OF ITS SERVICES OR BY
                    WAY OF ACTIONS TAKEN OUTSIDE OF THE USE OF ANY OF
                    CLAIMANT.IO'S TOOLS AND SERVICES. I AGREE TO HOLD
                    CLAIMANT.IO HARMLESS FOR ANY DISAGREEABLE RESULT STEMMING
                    FROM ANY USE OF CLAIMANT.IO OR LACK THERE OF. I AGREE TO
                    INDEMNIFY CLAIMANT.IO FOR ANY DAMAGES INCURRED BY ANY PARTY
                    STEMMING FROM MY CLAIM OR THE USE OF CLAIMANT.IO FOR ANY
                    PURPOSE. I UNDERSTAND THAT CALIMANT.IO HAS MADE NO
                    ASSURANCES OR PROMISES TO FULLFILL ANY EXPECTATIONS I MAY
                    HAVE FROM THE USE OF CLAIMANT.IO AND I CERTIFY THAT
                    CLAIMANT.IO IS NOT BEING COMPENSATED BASED ON MY RECOVERY
                    FROM MY CLAIM NOR IS ANY COMPENSATION BASED ON WHETHER MY
                    CLAIM IS SUCCESSFUL OR NOT BUT CLAIMANT.IO IS BEING
                    COMPENSATED FOR MY USE OF THE CLAIMANT.IO PLATFORM
                    REGARDLESS OF THE OUTCOME OF MY CLAIM. I FURTHER UNDERTAND
                    THAT I HAVE AND SHOULD EXERCISE THE RIGHT TO HAVE
                    REPRESENTATION AND MAY HAVE REPRESENTATION WHILE USING THIS
                    TOOL. I ALSO UNDERSTAND THAT CLAIMANT.IO MAY SUGGEST AN
                    ATTORNEY TO ME AND IN DOING SO I RELEASE CLAIMANT.IO FROM
                    ANY LIABILITY THAT MAY STEM FROM MY RETENTION OF ANY
                    ATTORNEY SUGGESTED BY CLAIMANT.IO AND I UNDERSTAND THAT
                    CLAIMANT.IO IS IN NO WAY RESPONSIBLE FOR THE ACTIONS OF ANY
                    OF THE SERVICE PROVIDERS THAT UTILIZE ITS PLATFORM INCLUDING
                    ATTORNEYS AND THEIR STAFF. I FURTHER UNDERSTAND THAT IT IS
                    MY RESPONSIBILITY TO JUDGE THE CHARACTER AND MERITS OF ANY
                    ATTORNEY I SELECT AND IT IS THE ATTORNEY'S DUTY TO DO THEIR
                    DUE DILIGENCE WHEN DECIDING TO TAKE MY CLAIM AND MYSELF ON
                    AS A CLIENT. THAT DECISION IS MADE BY ME AND THE ATTORNEY
                    WITHOUT ANY SAY ON THE PART OF CLAIMANT.IO AND THAT DECISION
                    IS MADE PRIOR TO MY SIGNING ANY RETAINER AGREEMENT WITH ANY
                    ATTORNEY. I UNDERSTAND THAT ONCE I HAVE AGREED TO A
                    SETTLEMENT AND RELEASED THE RESPONSIBLE PARTY FROM ALL
                    LIABILITY, I MAY NO LONGER SEEK ANY DAMAGES FOR THE CLAIM AS
                    TO THAT RESPONSIBLE PARTY UNLESS AND UNTIL I HAVE A SEPARATE
                    CLAIM WITH DIFFERENT FACTS AND CIRCUMSTANCES......
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn text @click="(agreement = false), (dialog = false)"
                      >No</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      class="white--text"
                      color="light-green accent-1 accent-4"
                      @click="(agreement = true), (dialog = false)"
                      >Yes</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  color="green"
                  @click="signup"
                  :disabled="!agreement"
                  :loading="loading"
                  class="ma-2"
                  >BEGIN</v-btn
                >
              </v-card-actions>
              <v-expand-transition>
                <div v-if="signupMessage">{{ signupMessage }}</div>
              </v-expand-transition>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-flex>

      <v-flex xs0 sm0 md2 lg2></v-flex>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "HelloWorld",

  data: () => ({
    email: null,
    password: null,
    loginEmail: null,
    logInPassword: null,
    continue: false,
    expand: false,
    expand2: false,
    agreement: false,
    dialog: false,
    loader: null,
    signupMessage: null,
    loginMessage: null,
    loading: false,
    loginLoading: false,
    rules: {
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    personalInjury: null,
    propertyDamage: null,
    damageType: [],
    tempInjury: null,
    tempPainLevel: 1,
    tempInjuryQualifier: null,
    tempInjuryTreatment: null,
    tempInjuryCost: null,
    listingInjuries: false,
    showValueDialog: false,
    injuries: [
      // {
      //   text: "Broken hip",
      //   painLevel: 7,
      //   qualifier: "Completely Healed",
      //   treatment: true,
      //   cost: "3000.00",
      // },
    ],
    doneListingInjuries: false,
    doneAddingTotalCost: false,
    totalInjuryCost: null,
    totalPropertyDamageCost: null,
    medicalDamages: null,
    numberOfMedicalProviders: 0,
    showValue: false,
    claimValue: 0.0,
    contactDialog: false,
    contactEmail: null,
    whatHappened: null,
    fullName: null,
    isLoading: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    multiple() {
      let painArray = [];
      let dividend = 3;
      this.injuries.forEach((injury) => {
        if (injury.painLevel) {
          painArray.push(injury.painLevel);
        }
        if (injury.treatment === true) {
          dividend = 2;
        }
      });
      // if (this.injuries.includes((injury) => injury.treatment === true)) {
      //   console.log("injury has treatement");
      //   dividend = 2;
      // }
      let highestVal = Math.max(...painArray);
      let finalMultiple = highestVal / dividend;
      if (finalMultiple >= 2) {
        return finalMultiple;
      } else {
        return 1.5;
      }
    },
    qualifier() {
      let totalQualifier = 0;
      this.injuries.forEach((injury) => {
        if (injury.qualifier) {
          totalQualifier += injury.qualifier;
        }
      });
      return totalQualifier;
    },
    // claimValue() {
    //   let percentage = this.totalInjuryCost * this.qualifier;
    //   let val = this.totalInjuryCost * this.multiple;
    //   let finalVal = val + percentage;
    //   return finalVal;
    // },

    // claimValue() {

    // ================KINDA WORKS==============
    // let totalCost = 0.0;
    // let injuryPainLevelTotal = 1;
    // let painLevelAverage = 1;
    // let totalValue = 0.0;
    // let injuryCount = 0;
    // let multiple = 1;

    // this.injuries.forEach((injury) => {
    //   injuryCount++;
    //   let cost = 0.0;
    //   if (injury.cost) {
    //     cost = parseFloat(injury.cost);
    //   }
    //   if (injury.qualifier) {
    //     let qualifier = parseFloat(injury.qualifier);
    //     let percentage = cost * qualifier;
    //     cost += percentage;
    //   }
    //   if (injury.painLevel) {
    //     injuryPainLevelTotal += injury.painLevel;
    //   }

    //   totalCost += cost;
    // });
    // painLevelAverage = injuryPainLevelTotal / injuryCount;

    // multiple = Math.floor(painLevelAverage);

    // totalValue = totalCost * multiple;

    // // let finalTotalValue = parseFloat(totalValue);

    // // return finalTotalValue;
    // return totalValue;

    // ================ABOVE KINDA WORKS ===================

    // let costTotalInt = 0.0;
    // let painLevelTotal = 0;
    // let injuryCount = 0;
    // let valueTotal = 0.0;
    // let multiple = 0.0;
    // let cost = 0.0;
    // let finalCostTotalInt = 0.0;
    // // if (this.injuries.length) {
    // this.injuries.forEach((injury) => {
    //   injuryCount++;
    //   console.log("injury", injury);
    //   console.log("injuryCount", injuryCount);
    //   if (injury.cost) {
    //     console.log("there is cost");
    //     cost = parseFloat(injury.cost);
    //     if (injury.qualifier) {
    //       //if there is a qualifier
    //       console.log("there is qualifier");

    //       if (injury.qualifier === "Getting Better") {
    //         console.log("getting better");

    //         let percentage = cost * 0.05;
    //         cost += percentage;
    //         // return cost;
    //       } else if (injury.qualifier === "Remaining The Same") {
    //         console.log("remaining the same");

    //         let percentage = cost * 0.1;
    //         cost += percentage;
    //         // return cost;
    //       } else if (injury.qualifier === "Getting Worse") {
    //         console.log("getting worse");

    //         let percentage = cost * 0.15;
    //         cost += percentage;
    //         // return cost;
    //       }
    //     }
    //     console.log("cost", cost);
    //     costTotalInt += cost;
    //   }

    //   if (injury.painLevel) {
    //     painLevelTotal += injury.painLevel;
    //   }
    // });

    // if (injuryCount !== 0 && painLevelTotal !== 0) {
    //   multiple = painLevelTotal / injuryCount;
    //   console.log("multiple", multiple);
    //   let wholeMultiple = Math.floor(multiple);
    //   let reduction = wholeMultiple / 2;
    //   console.log("reduction", reduction);
    //   finalCostTotalInt = costTotalInt * reduction;
    //   console.log("finalCostTotalInt", finalCostTotalInt);
    // }
    // // let finalmultiple = multiple / 2;
    // // finalCostTotalInt = costTotalInt * (multiple / 2);
    // // console.log("finalCostTotalInt", finalCostTotalInt);
    // // let finalCostTotalInt = costTotalInt * finalmultiple;
    // // let finalCostTotalInt = costTotalInt * multiple;

    // console.log("costTotalInt", costTotalInt);

    // valueTotal = finalCostTotalInt.toLocaleString("en-US", {
    //   style: "currency",
    //   currency: "USD",
    // });
    // // }
    // console.log("valueTotal", valueTotal);
    // return valueTotal;
    // },
  },
  methods: {
    sendContactEmail() {
      this.isLoading = true;
      const sendTestEmail = firebase.functions().httpsCallable("sendTestEmail");
      sendTestEmail({
        contactEmail: this.contactEmail,
        message: this.whatHappened,
        name: this.fullName,
      }).then(() => {
        this.isLoading = false;
        this.contactDialog = false;
      });
    },
    doneInjuries() {
      this.listingInjuries = false;
      if (this.totalInjuryCost) {
        let totalCost = parseFloat(this.totalInjuryCost);
        console.log("totalCost", totalCost);
        let qual = parseFloat(this.qualifier);
        console.log("qual", qual);

        let percentage = totalCost * qual;
        console.log("percentage", percentage);
        let mult = parseFloat(this.multiple);
        console.log("mult", mult);
        let val = totalCost * mult;
        console.log(val);
        let finalInjuryVal = val + percentage;
        let finalPropertyVal = 0.0;
        if (this.totalPropertyDamageCost) {
          finalPropertyVal = parseFloat(this.totalPropertyDamageCost);
        }
        this.claimValue = finalInjuryVal + finalPropertyVal;
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
        // console.log("finalVal", finalVal);
      }
    },
    valueClaim() {
      this.listingInjuries = false;
      if (this.totalInjuryCost) {
        let totalCost = parseFloat(this.totalInjuryCost);
        console.log("totalCost", totalCost);
        let qual = parseFloat(this.qualifier);
        console.log("qual", qual);

        let percentage = totalCost * qual;
        console.log("percentage", percentage);
        let mult = parseFloat(this.multiple);
        console.log("mult", mult);
        let val = totalCost * mult;
        console.log(val);
        let finalInjuryVal = val + percentage;
        let finalPropertyVal = 0.0;
        if (this.totalPropertyDamageCost) {
          finalPropertyVal = parseFloat(this.totalPropertyDamageCost);
        }
        this.claimValue = finalInjuryVal + finalPropertyVal;
        this.showValueDialog = true;
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
        // this.contactDialog = true;
        // console.log("finalVal", finalVal);
      }
    },
    // valueClaim() {
    //   let totalCost = 0.0;
    //   let injuryPainLevelTotal = 1;
    //   let painLevelAverage = 1;
    //   let totalValue = 0.0;
    //   let injuryCount = 0;
    //   let multiple = 1;
    //   this.injuries.forEach((injury) => {
    //     injuryCount++;
    //     let cost = 0.0;
    //     if (injury.cost) {
    //       cost = parseFloat(injury.cost);
    //     }
    //     if (injury.qualifier) {
    //       let qualifier = parseFloat(injury.qualifier);
    //       let percentage = cost * qualifier;
    //       cost += percentage;
    //     }
    //     if (injury.painLevel) {
    //       injuryPainLevelTotal += injury.painLevel;
    //     }

    //     totalCost += cost;
    //   });
    //   painLevelAverage = injuryPainLevelTotal / injuryCount;

    //   multiple = Math.floor(painLevelAverage);

    //   totalValue = totalCost * multiple;

    //   // let finalTotalValue = parseFloat(totalValue);

    //   // return finalTotalValue;
    //   this.claimValue = totalValue;
    // },

    signup() {
      console.log(this.user);
      console.log("loading from submit");
      this.loading = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((resp) => {
          console.log(resp.user.uid);
          firebase
            .firestore()
            .collection("claimants")
            .doc(resp.user.uid)
            .set({
              id: resp.user.uid,
              email: this.email,
              claims: [],
            })
            .then(() => {
              this.loading = false;
              // this.$router.push("/claims");
            })
            .catch((e) => {
              this.message = e.message;
            });
        })
        .catch((e) => {
          this.message = e.message;
          if (e.code === "auth/email-already-in-use") {
            console.log("pushing to /");
            this.$router.push("/");
          }
        });
    },
    login() {
      this.loginLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.loginEmail, this.logInPassword)
        .then(() => {
          this.loginLoading = false;
          // this.$router.push("/claims");
        })
        .catch((e) => {
          this.loginMessage = e.loginMessage;
        });
      // this.$router.push("/");
    },
    addInjury() {
      if (this.tempInjury !== null && this.tempInjury !== undefined) {
        let cost = 0;
        if (this.tempInjuryCost !== null && this.tempInjuryCost !== undefined) {
          cost = parseFloat(this.tempInjuryCost);
        }
        let injury = {
          text: this.tempInjury,
          painLevel: this.tempPainLevel,
          qualifier: this.tempInjuryQualifier,
          treatment: this.tempInjuryTreatment,
          cost: cost,
        };

        this.injuries.push(injury);
        this.tempInjury = null;
        this.tempPainLevel = 1;
        this.tempInjuryQualifier = null;
        this.tempInjuryTreatment = null;
        this.tempInjuryCost = null;
        console.log("Injury List: ", this.injuries);
        let element = document.getElementById("focusElement");
        let dialog = document.getElementById("dialog");
        dialog.scrollTo(0, 0);
        element.focus();
        this.listingInjuries = false;
      }
    },
    removeInjury(i) {
      this.injuries.splice(i, 1);
    },
    startOver() {
      this.personalInjury = null;
      this.propertyDamage = null;
      this.damageType = [];
      this.tempInjury = null;
      this.tempPainLevel = 1;
      this.tempInjuryQualifier = null;
      this.tempInjuryTreatment = null;
      this.tempInjuryCost = null;
      this.listingInjuries = false;
      this.injuries = [];
      this.doneListingInjuries = false;
      this.medicalDamages = null;
      this.numberOfMedicalProviders = 0;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/claims");
      }
    },

    expand(value) {
      if (value === true) {
        this.expand2 = false;
      }
    },
    expand2(value) {
      if (value === true) {
        this.expand = false;
      }
    },
    personalInjury(value) {
      if (value === true) {
        this.listingInjuries = true;
      }
    },
    injuries(val) {
      console.log("injuries from watch", val);
    },
    contactDialog(val) {
      if (val === true) {
        this.showValueDialog = false;
      }
    },
    showValueDialog(val) {
      if (val === true) {
        this.contactDialog = false;
      }
    },
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.v-slider__thumb-label {
  height: 22px !important;
  width: 22px !important;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
