<template>
  <v-container>
    <div>PROGRESS</div>
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-linear :value="progress" color="cyan"></v-progress-linear>
      </v-col>
      <v-btn small fab v-if="isMobile" @click.stop="drawer = !drawer"
        ><v-icon>mdi-dots-vertical</v-icon></v-btn
      >
      <v-expand-transition>
        <v-flex
          xs12
          sm12
          md3
          lg3
          v-if="drawer"
          style="
            overflow-x: scroll;
            overflow: auto;
            border-left: 1px solid white;
            border-top: 1px solid white;
            height: 80vh;
          "
        >
          <v-list dense rounded width="auto" class="ma-2 text-left">
            <v-list-item-group active-class="pink--text" v-model="step">
              <div v-if="claim.name">
                <v-list-item-title
                  class="font-weight-bold text--accent-3 text-center"
                  >{{ claim.name
                  }}<v-btn
                    small
                    rounded
                    text
                    outlined
                    color="yellow"
                    @click="editClaimName"
                    >edit</v-btn
                  ></v-list-item-title
                >
              </div>
              <div v-for="(item, index) in menuItems" :key="index">
                <v-divider></v-divider>
                <v-list-item-title>{{
                  item.title.toUpperCase()
                }}</v-list-item-title>
                <v-divider></v-divider>
                <v-list-item v-for="(subItem, i) in item.subItems" :key="i">
                  <v-list-item-content @click="selectedItem = subItem">
                    <v-list-item-title
                      >{{ subItem.title
                      }}<v-icon
                        right
                        v-if="
                          claim[`${subItem.model}`] &&
                          claim[`${subItem.model}`] !== null
                        "
                        color="green"
                        >mdi-check</v-icon
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-flex>
      </v-expand-transition>

      <v-flex
        xs12
        sm12
        md7
        lg6
        style="border-right: 1px #1de9b6 solid; border-left: 1px #1de9b6 solid"
      >
        <v-fade-transition>
          <v-card
            class="ma-auto"
            max-width="500"
            height="auto"
            outlined
            rounded
            text
            v-if="claimHasNoName || editingClaimName"
          >
            <v-card-title>GIVE YOUR CLAIM A NAME</v-card-title>
            <v-form @sumbit.prevent="addClaimName">
              <v-text-field
                class="ma-3"
                label="NAME"
                outlined
                dense
                v-model="claimName"
                clearable
              ></v-text-field>
              <v-btn
                outlined
                rounded
                color="green"
                @click="addClaimName"
                class="ma-2"
                >NEXT</v-btn
              >
            </v-form>
          </v-card>
        </v-fade-transition>
        <!-- <v-fade-transition> -->
        <v-card class="mx-auto" max-width="500" outlined rounded text>
          <!-- <v-form @submit.prevent="" @input="logClaim"> -->
          <!-- <v-form @submit.prevent=""> -->
          <v-window v-model="step">
            <div v-for="(item, index) in menuItems" :key="index">
              <!-- <div v-for="(subItem, i) in item.subItems" :key="i"> -->
              <v-window-item
                v-for="(subItem, subI) in item.subItems"
                :key="subI"
              >
                <!-- <v-card-text> -->
                <v-card-title wrap>
                  <p>{{ subItem.title }}</p>
                  <!-- <v-btn @click="logClaim"></v-btn> -->
                  <div
                    v-if="subItem.message"
                    class="font-weight-bold text--accent-3 text-center"
                  >
                    {{ subItem.message }}
                  </div>
                </v-card-title>
                <div v-if="subItem.type === 'textField'">
                  <v-text-field
                    class="ma-3"
                    :label="subItem.title"
                    outlined
                    dense
                    v-model="claim[`${subItem.model}`]"
                    clearable
                    autofocus
                    @keydown.enter="saveAndContinue"
                    @input="allowNext = true"
                  ></v-text-field>
                </div>
                <div v-if="subItem.type === 'date'">
                  <v-text-field
                    class="ma-3"
                    :label="subItem.title"
                    outlined
                    dense
                    v-model="claim[`${subItem.model}`]"
                    clearable
                    v-mask="dateMask"
                    autofocus
                    @keydown.enter="saveAndContinue"
                    @input="allowNext = true"
                  ></v-text-field>
                </div>
                <div v-if="subItem.type === 'phone'">
                  <v-text-field
                    class="ma-3"
                    :label="subItem.title"
                    outlined
                    dense
                    v-model="claim[`${subItem.model}`]"
                    clearable
                    v-mask="phoneMask"
                    autofocus
                    @keydown.enter="saveAndContinue"
                    @input="allowNext = true"
                  ></v-text-field>
                </div>
                <div v-if="subItem.type === 'email'">
                  <v-text-field
                    class="ma-3"
                    :label="subItem.title"
                    outlined
                    dense
                    v-model="claim[`${subItem.model}`]"
                    clearable
                    type="email"
                    autofocus
                    @keydown.enter="saveAndContinue"
                    @input="allowNext = true"
                  ></v-text-field>
                </div>
                <div v-if="subItem.type === 'boolDependentSet'">
                  <v-radio-group
                    v-model="claim[`${subItem.model}`]"
                    @change="allowNext = true"
                  >
                    <v-radio
                      label="YES"
                      value="true"
                      @change="saveDontContinue"
                    ></v-radio>
                    <v-radio
                      label="NO"
                      value="false"
                      @input="allowNext = true"
                    ></v-radio>
                  </v-radio-group>
                  <v-expand-transition>
                    <div v-if="claim[`${subItem.model}`] == 'true'">
                      <div
                        v-for="(dq, index) in subItem.dependentSet"
                        :key="index"
                      >
                        <div v-if="dq.type === 'depTextField'">
                          <v-text-field
                            class="ma-3"
                            :label="dq.title"
                            outlined
                            dense
                            v-model="claim[`${dq.model}`]"
                            @input="allowNext = true"
                          ></v-text-field>
                        </div>
                        <div v-if="dq.type === 'depPhone'">
                          <v-text-field
                            class="ma-3"
                            :label="dq.title"
                            outlined
                            dense
                            v-mask="phoneMask"
                            v-model="claim[`${dq.model}`]"
                            @input="allowNext = true"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                  </v-expand-transition>
                </div>
                <div v-if="subItem.type === 'optionsMultiple'">
                  <div v-for="(option, i) in subItem.options" :key="i">
                    <v-checkbox
                      v-model="subItem.response"
                      :label="option"
                      :value="option"
                      @change="allowNext = true"
                    >
                    </v-checkbox>
                  </div>
                </div>
                <div v-if="subItem.type === 'options'">
                  <div v-for="(option, i) in subItem.options" :key="i">
                    <v-checkbox
                      v-model="claim[`${subItem.model}`]"
                      :label="option"
                      :value="option"
                      @change="allowNext = true"
                    >
                    </v-checkbox>
                  </div>
                  <div v-if="claim[`${subItem.model}`] == 'Other'">
                    <v-expand-transition>
                      <v-text-field
                        class="ma-3"
                        label="Please describe"
                        outlined
                        dense
                        v-model="claim[`${subItem.model}Description`]"
                        @input="allowNext = true"
                        @keydown.enter="saveAndContinue"
                      ></v-text-field>
                    </v-expand-transition>
                  </div>
                </div>
                <!-- </v-card-text> -->
                <div v-if="subItem.type === 'textArea'">
                  <v-textarea
                    class="ma-3"
                    :label="subItem.title"
                    outlined
                    dense
                    v-model="claim[`${subItem.model}`]"
                    clearable
                    autofocus
                    @keydown.enter="saveAndContinue"
                    @input="allowNext = true"
                  ></v-textarea>
                </div>
                <div v-if="subItem.type === 'list'">
                  <v-expand-transition
                    v-for="(resp, i) in subItem.response"
                    :key="i"
                  >
                    <!-- <v-card> -->
                    <v-row class="ma-auto">
                      <!-- <v-col class="ma-auto"> -->
                      <!-- <v-spacer></v-spacer> -->
                      <h3 class="font-weight-bold ma-3">
                        {{ i + 1 }}. {{ resp }}
                      </h3>

                      <v-btn
                        class="ma-3"
                        outlined
                        rounded
                        color="red"
                        depressed
                        @click="removeListItem(index, subI, i)"
                        small
                        >remove</v-btn
                      >
                      <!-- <v-spacer></v-spacer> -->
                      <!-- </v-col> -->
                    </v-row>
                    <!-- </v-card> -->
                  </v-expand-transition>
                  <v-text-field
                    class="ma-3"
                    :label="subItem.title"
                    outlined
                    dense
                    v-model="tempListItem"
                    clearable
                    autofocus
                    @keydown.enter="addListItem(index, subI)"
                    @input="allowNext = true"
                  ></v-text-field>
                  <v-btn
                    outlined
                    rounded
                    color="green"
                    depressed
                    @click="addListItem(index, subI)"
                    >ADD</v-btn
                  >
                </div>
                <!-- <v-card-actions>
                    <v-btn :disabled="step === 0" text @click="step--">
                      Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      rounded
                      color="green"
                      depressed
                      @click="saveAndContinue"
                    >
                      Next
                    </v-btn>
                  </v-card-actions> -->
              </v-window-item>
            </div>
            <!-- </div> -->
          </v-window>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn :disabled="step === 0" text @click="step--"> Back </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              rounded
              color="green"
              depressed
              @click="saveAndContinue"
            >
              Next
            </v-btn>
          </v-card-actions>
          <!-- </v-form> -->
          <!-- <v-divider></v-divider> -->
        </v-card>
        <!-- </v-fade-transition> -->
      </v-flex>
      <v-flex xs12 sm12 md2 lg3>
        <v-card-title>ESTIMATED VALUE</v-card-title>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { mask } from "vue-the-mask";

// import * as moment from "moment";

export default {
  name: "Claim",
  directives: {
    mask,
  },
  data: () => ({
    isMobile: false,
    drawer: true,
    step: 0,
    editingClaimName: false,
    claimName: null,
    tempListItem: null,
    claim: {
      damageType: [],
      claimType: [],
      damages: [],
    },
    claimant: null,
    selectedItem: null,
    selectedSubItem: [],
    dateMask: "##/##/####",
    phoneMask: "(###) ###-####",
    allowNext: false,
    menuItems: [
      {
        icon: "",
        title: "Contact",
        link: "/",
        subItems: [
          {
            title: "Claimant's First Name",
            model: "clFirstName",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 1,
          },
          {
            title: "Claimant's Last Name",
            model: "clLastName",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 2,
          },
          {
            title: "Claimant's Date of Birth",
            model: "clDateOfBirth",
            type: "date",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 3,
          },
          {
            title: "Claimant's Street Address",
            model: "clStreetAddress",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 4,
          },
          {
            title: "Claimant's City",
            model: "clCity",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 5,
          },
          {
            title: "Claimant's State",
            model: "clState",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 6,
          },
          {
            title: "Claimant's Zip",
            model: "clZip",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 7,
          },
          {
            title: "Claimant's Phone",
            model: "clPhone",
            type: "phone",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 8,
          },
          {
            title: "Claimant's Email",
            model: "clEmail",
            type: "email",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 9,
          },
        ],
      },
      {
        icon: "",
        title: "Representation",
        link: "/",
        subItems: [
          {
            title: "Do You Have an Attorney?",
            message:
              "You dont need to have an attorney but if you do, we can work with them to help you get your claim in order.",
            model: "clIsRepresented",
            type: "boolDependentSet",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 10,
            dependentSet: [
              {
                title: "Attorney's FULL Name",
                model: "clAttorneyName",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.1,
              },
              {
                title: "Attorney's Law Firm Name",
                model: "clAttorneyFirm",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.2,
              },

              {
                title: "Attorney's Street Address",
                model: "clAttorneyStreetAddress",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.3,
              },
              {
                title: "Attorney's City",
                model: "clAttorneyCity",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.4,
              },
              {
                title: "Attorney's State",
                model: "clAttorneyState",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.5,
              },
              {
                title: "Attorney's Zip",
                model: "clAttorneyZip",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.6,
              },
              {
                title: "Attorney's Phone",
                model: "clAttorneyPhone",
                type: "depPhone",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.7,
              },
              {
                title: "Attorney's Email",
                model: "clAttorneyEmail",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
                step: 10.8,
              },
            ],
          },
        ],
      },
      {
        icon: "",
        title: "Claim Details",
        link: "/matters",
        subItems: [
          {
            title: "Type Of Claim",
            message: "Lets determine what kind of claim you have.",
            model: "claimType",
            type: "options",
            options: [
              "Auto Accident",
              "Product liability",
              "Medical malpractice",
              "Premise libility/Slip and fall",
              "Eminent domain/Inverse condemnation",
              "Fire damage to person and/or property",
              "Professional Negligence",
            ],
            response: [],
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 11,
          },
          {
            title: "Incident Date",
            message:
              "Please provide an accurate date for the incident that created this this claim. Keep in mind that most states have limitations on how much time you have to make certain claims. Be sure to know these rules in your state.",
            model: "incidentDate",
            type: "date",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 12,
          },
          {
            title: "Incident Location",
            message:
              "Provide the nearest address to the location where the incident occurred.",
            model: "incidentLocation",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 13,
          },
          {
            title: "Incident Time (optional)",
            message:
              "Please indicate the time of day when the incident occurred if applicable.",
            model: "incidentTime",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 14,
          },
          {
            title: "Incident Description",
            message:
              "Briefly describe the events that gave rise to your calim. Please be detailed in your description.",
            model: "incidentDescription",
            type: "textArea",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 15,
          },
        ],
      },
      {
        icon: "mdi-folder-plus",
        title: "Responsible Parties",
        link: "/matters/new",
        subItems: [
          {
            title: "Responsible Party Type",
            message: "Lets determine who is responsible for the incident.",
            model: "rpType",
            type: "options",
            options: ["Individual", "Business", "Other"],
            response: [],
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 17,
          },
          {
            title: "Responsible Party's FULL Name",
            model: "rpFullName",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 18,
          },

          {
            title: "Responsible Party's Street Address",
            model: "rpStreetAddress",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 19,
          },
          {
            title: "Responsible Party's City",
            model: "rpCity",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 20,
          },
          {
            title: "Responsible Party's State",
            model: "rpState",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 21,
          },
          {
            title: "Responsible Party's Zip",
            model: "rpZip",
            type: "textField",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 22,
          },
          {
            title: "Responsible Party's Phone",
            model: "rpPhone",
            type: "phone",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 23,
          },
          {
            title: "Responsible Party's Email",
            model: "rpEmail",
            type: "email",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 24,
          },
        ],
      },
      {
        icon: "mdi-account-outline",
        title: "Insurance",
        link: "/profile",
        subItems: [
          {
            title: "Claimant's Insurnace",
            message:
              'Did you have insurance for this type of incident? Only mark "YES" if you were insured at the time of the incident. For example, if you were involved in a car accident and you were insured at the time, you would mark "YES" to this question.',
            model: "clInsured",
            type: "boolDependentSet",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            dependentSet: [
              {
                title: "Name of YOUR Insurance Provider",
                message: "Please provide the name of YOUR insurance oprovider.",
                model: "clInsuranceName",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },

              {
                title: "YOUR Insurance Provider's Street Address",
                model: "clInsuranceStreetAddress",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Insurance Provider's City",
                model: "clInsuranceCity",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Insurance Provider's State",
                model: "clInsuranceState",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Insurance Provider's Zip",
                model: "clInsuranceZip",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Insurance Provider's Phone",
                model: "clInsurancePhone",
                type: "depPhone",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Insurance Provider's Email (optional)",
                model: "clInsuranceEmail",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Insurance Policy Number",
                model: "clInsurancePolicyNumber",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Insurance Claim Number",
                message:
                  "If you have already opened a claim with YOUR own insurance provider, please provide the claim number if you have it.",
                model: "clInsuranceClaimNumber",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "YOUR Adjuster Name (optional)",
                message:
                  "If you have already contacted this provider regarding this claim, you may have been provided an adjuster. If you have an adjuster, please provide enter it here.",
                model: "clInsuranceAdjuster",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
            ],
          },
          {
            title: "Responsible Party's Insurnace",
            message:
              "Did the responsible party have insurance for this type of incident?",
            model: "rpInsured",
            type: "boolDependentSet",
            response: null,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            dependentSet: [
              {
                title: "Responsible Party's Insurance Provider",
                message: "Please provide the name of YOUR insurance oprovider.",
                model: "rpInsuranceName",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },

              {
                title: "Responsible Party's Street Address",
                model: "rpInsuranceStreetAddress",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's City",
                model: "rpInsuranceCity",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's State",
                model: "rpInsuranceState",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's Zip",
                model: "rpInsuranceZip",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's Phone",
                model: "rpInsurancePhone",
                type: "depPhone",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's Email (optional)",
                model: "rpInsuranceEmail",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's Policy Number",
                model: "rpInsurancePolicyNumber",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's Claim Number",
                message:
                  "If you have already opened a claim with the responsible party's insurance provider, please provide the claim number here.",
                model: "rpInsuranceClaimNumber",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
              {
                title: "Responsible Party's Adjuster Name (optional)",
                message:
                  "If you have already contacted this provider regarding this claim, you may have been provided an adjuster. If you have an adjuster, please provide enter it here.",
                model: "rpInsuranceAdjuster",
                type: "depTextField",
                response: null,
                userNotes: [],
                adminNotes: [],
                timestamp: null,
              },
            ],
          },
        ],
      },
      {
        icon: "mdi-account-outline",
        title: "Damages",
        link: "/profile",
        subItems: [
          {
            title: "Type Of Damege",
            message:
              "Lets determine what kind of damages you have. You may select both options if your damages include both personal injury and property damage. Other kinds of damage will be covered as you move on with the claim.",
            model: "damageType",
            type: "optionsMultiple",
            options: ["Personal Injury", "Property Damage"],
            response: [],
            userNotes: [],
            adminNotes: [],
            timestamp: null,
            step: 18,
          },
        ],
      },
    ],
    // user: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    progress() {
      let entries = Object.entries(this.claim);
      // console.log("entries", entries);

      let progressPoints = [];

      entries.forEach((entry) => {
        // console.log(entry);
        if (entry[1] !== null && entry[1] !== "") {
          progressPoints.push(entry[1]);
        }
      });
      let progLength = progressPoints.length;
      // console.log("totalItmes", this.totalItems);
      // console.log("progress length", progLength);
      let prog = (progLength / 35) * 100;
      // console.log("raw progress", prog);

      let finalProg = Math.round(prog);

      return finalProg;
    },
    claimHasNoName() {
      let name = this.claim.name;
      if (name !== null && name !== undefined) {
        return false;
      } else {
        return true;
      }
    },
    totalItems() {
      let total = 0;

      this.menuItems.forEach((item) => {
        item.subItems.forEach(() => {
          total++;
        });
      });
      return total;
    },
  },
  methods: {
    addListItem(menIndex, subItemIndex) {
      console.log("menIndex", menIndex);
      console.log("subindex", subItemIndex);
      this.menuItems[menIndex].subItems[subItemIndex].response.push(
        this.tempListItem
      );
      this.tempListItem = null;
      this.allowNext = true;
    },
    removeListItem(menIndex, subItemIndex, responseIndex) {
      console.log("menIndex", menIndex);
      console.log("subindex", subItemIndex);
      console.log("respIndex", responseIndex);
      this.menuItems[menIndex].subItems[subItemIndex].response.splice(
        responseIndex,
        1
      );
      this.allowNext = true;
    },
    logClaim() {
      console.log(this.claim);
      console.log("CHANGE");
    },
    saveAndContinue() {
      if (this.allowNext === true) {
        this.step += 1;
        // console.log("step:", this.step);
        this.claim.damageType = this.menuItems[5].subItems[0].response;
        this.claim.injuryList = this.menuItems[5].subItems[1].response;
        console.log("claim before saving", this.claim);
        firebase
          .firestore()
          .collection("claimants")
          .doc(this.user.id)
          .collection("claims")
          .doc(this.claim.id)
          .update(this.claim)
          .then(() => {
            console.log("sucessfully updated claim", this.claim);

            this.allowNext = false;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.step += 1;
      }
    },
    saveDontContinue() {
      console.log("claim before saving", this.claim);
      firebase
        .firestore()
        .collection("claimants")
        .doc(this.user.id)
        .collection("claims")
        .doc(this.claim.id)
        .update(this.claim)
        .then(() => {
          console.log("sucessfully updated claim", this.claim);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addClaimName() {
      this.claim.name = this.claimName;
      firebase
        .firestore()
        .collection("claimants")
        .doc(this.user.id)
        .collection("claims")
        .doc(this.claim.id)
        .update(this.claim)
        .then(() => {
          console.log("sucessfully updated claim");
          this.editingClaimName = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    editClaimName() {
      this.editingClaimName = !this.editingClaimName;
    },

    onResize() {
      this.isMobile = window.innerWidth < 800;
    },
  },
  watch: {
    user(value) {
      if (value === null && value === undefined) {
        this.$router.push("/");
      } else {
        console.log("user from watch in claims", value.id);
        let userRef = firebase
          .firestore()
          .collection("claimants")
          .doc(value.id);

        userRef.onSnapshot((res) => {
          let data = res.data();
          console.log("data from snapshot", data);
          this.claimant = data;
        });
        let claimId = this.$router.currentRoute.params.id;
        console.log("claimId", claimId);
        let claimRef = firebase
          .firestore()
          .collection("claimants")
          .doc(value.id)
          .collection("claims")
          .doc(claimId);
        claimRef.onSnapshot((res) => {
          this.claim = res.data();
          this.menuItems[5].subItems[0].response = this.claim.damageType;
          // if (this.claim.injuryList) {
          if (this.claim.damageType.includes("Personal Injury")) {
            this.menuItems[5].subItems[1] = {
              title: "Injury List Items",
              message:
                'Please list your injuries here. For each injury, write a brief description and click "ADD" then input the next one. Make sure to click "ADD" on your last input or it will not be included. You can always return and add or remove items from this list.',
              model: "injuryList",
              type: "list",
              response: this.claim.injuryList,
              userNotes: [],
              adminNotes: [],
              timestamp: null,
            };
            // this.menuItems[5].subItems[1].response = this.claim.injuryList;
          }
          // }
          console.log("claim from watch @ claim", this.claim);
        });
      }
    },
    isMobile(val) {
      if (val === true) {
        this.drawer = !this.drawer;
      } else {
        this.drawer = true;
      }
    },
    claim(val) {
      console.log("claim at watch at claim", val);
      if (this.claim.clIsRepresented && this.claim.clIsRepresented !== "true") {
        console.log("changing atty data");
        console.log("changing atty data", this.claim.clIsRepresented);

        this.claim.clAttorneyName = null;
        this.claim.clAttorneyFirm = null;
        this.claim.clAttorneyStreetAddress = null;
        this.claim.clAttorneyCity = null;
        this.claim.clAttorneyState = null;
        this.claim.clAttorneyZip = null;
        this.claim.clAttorneyPhone = null;
        this.claim.clAttorneyEmail = null;
      }
      if (this.claim.clInsured && this.claim.clInsured !== "true") {
        this.claim.clInsuranceAdjuster = null;
        this.claim.clInsuranceName = null;
        this.claim.clInsuranceStreetAddress = null;
        this.claim.clInsuranceCity = null;
        this.claim.clInsuranceState = null;
        this.claim.clInsuranceZip = null;
        this.claim.clInsurancePhone = null;
        this.claim.clInsuranceEmail = null;
      }
      if (this.claim.damageType !== null && this.claim.damageType.length > 0) {
        this.menuItems[5].subItems[0].response = this.claim.damageType;
      }

      if (this.claim.rpType !== "other") {
        this.claim.rpTypeDescription = null;
      }

      if (this.claim.damageType !== null) {
        if (this.claim.damageType.includes("Personal Injury")) {
          this.menuItems[5].subItems[1] = {
            title: "Injury List Items",
            message:
              'Please list your injuries here. For each injury, write a brief description and click "ADD" then input the next one. Make sure to click "ADD" on your last input or it will not be included. You can always return and add or remove items from this list.',
            model: "injuryList",
            type: "list",
            response: this.claim.injuryList,
            userNotes: [],
            adminNotes: [],
            timestamp: null,
          };
        }
        // this.menuItems[5].subItems[1].response = this.claim.injuryList;
      }
    },
  },
  created() {
    // console.log("user from claim", this.user);
    let user = this.$store.getters.user;
    console.log("user from claim", user);

    // if (this.user !== null && this.user !== undefined) {
    if (user !== null && user !== undefined) {
      let userRef = firebase
        .firestore()
        .collection("claimants")
        // .doc(this.user.id);
        .doc(user.id);

      userRef.onSnapshot((res) => {
        let data = res.data();
        console.log("data from snapshot", data);
        this.claimant = data;
      });
      let claimId = this.$router.currentRoute.params.id;
      console.log("claimId", claimId);
      let claimRef = firebase
        .firestore()
        .collection("claimants")
        // .doc(this.user.id)
        .doc(user.id)
        .collection("claims")
        .doc(claimId);
      claimRef.onSnapshot((res) => {
        // let docChanges = res.docChanges();
        // console.log("res from created @ claim", res);
        this.claim = res.data();
        // console.log("claim from created @ claim", this.claim);
      });
    }
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
<style></style>
