import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import Vuetify from "vuetify/lib/";

Vue.use(Vuetify);
const opts = {};
export default new Vuetify(
  // {
  // theme: { dark: true },
  // }
  opts
);
