import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    loading: false,
    claims: [],
  },
  mutations: {
    setUser(state, payload) {
      console.log("setting user", payload);
      state.user = payload;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    autoLogin({ commit }, payload) {
      if (payload === null) {
        console.log("the user is null");
        commit("clearUser");
      } else {
        console.log("AUTO LOGIN payload", payload);
        // commit('setUser', { id: payload.uid, matterList: [] });
        commit("setUser", {
          id: payload.uid,
          email: payload.email,
        });
      }
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    // getClaim(state) {
    //   return claimId =>
    //     state.claims.find(claim => claim.id === claimId);
    // },
  },
  modules: {},
});
