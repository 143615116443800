<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12"> </v-col>
      <v-flex xs12 sm12 md4 lg4>
        <v-col>
          <!-- <h1 class="display-2 font-weight-bold mb-3">
            COMING SOON <br />
            HANG TIGHT!
          </h1> -->
          <div v-for="claim in claims" :key="claim.id">
            <div>{{ claim.name }} -- Created On: {{ claim.dateCreated }}</div>
            <v-btn
              outlined
              rounded
              color="yellow"
              small
              class="ma-2"
              @click="editClaim(claim.id)"
              >EDIT</v-btn
            >
            <hr />
          </div>
        </v-col>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4> </v-flex>
      <v-flex xs12 sm12 md4 lg4> </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import * as moment from "moment";

export default {
  name: "Claims",

  data: () => ({
    claims: null,
    claimant: null,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    editClaim(id) {
      this.$router.push(`/claims/${id}`);
    },
  },
  watch: {
    user(value) {
      if (value === null && value === undefined) {
        this.$router.push("/");
      } else {
        console.log("user from watch in claims", value.id);
        let userRef = firebase
          .firestore()
          .collection("claimants")
          .doc(value.id);

        userRef.onSnapshot((res) => {
          let data = res.data();
          console.log("data from snapshot", data);
          this.claimant = data;
        });
        let claimsRef = firebase
          .firestore()
          .collection("claimants")
          .doc(this.user.id)
          .collection("claims");
        claimsRef.onSnapshot((res) => {
          let claims = [];
          res.forEach((doc) => {
            claims.push(doc.data());
          });
          console.log("claims from watch @claims", claims);
          this.claims = claims;
          if (this.claims.length <= 0) {
            console.log("no claims");
            let newClaim = {
              id: null,
              name: null,
              dateCreated: moment().format("MMMM Do YYYY, h:mm:ss a"),
            };

            claimsRef.add(newClaim).then((docRef) => {
              claimsRef
                .doc(docRef.id)
                .update({
                  id: docRef.id,
                })
                .then(() => {
                  this.$router.push(`/claims/${docRef.id}`);
                });
            });
          }
        });
      }
    },
  },
  created() {
    console.log("user from claims", this.user);

    if (this.user !== null && this.user !== undefined) {
      let claimsRef = firebase
        .firestore()
        .collection("claimants")
        .doc(this.user.id)
        .collection("claims");
      claimsRef.onSnapshot((res) => {
        let claims = [];
        res.forEach((doc) => {
          claims.push(doc.data());
        });
        console.log("claims from created @ claims", claims);
        this.claims = claims;
        if (this.claims.length <= 0) {
          console.log("no claims");
          let newClaim = {
            id: null,
            name: null,
            dateCreated: moment().format("MMMM Do YYYY, h:mm:ss a"),
          };

          claimsRef.add(newClaim).then((docRef) => {
            claimsRef
              .doc(docRef.id)
              .update({
                id: docRef.id,
              })
              .then(() => {
                this.$router.push(`/claims/${docRef.id}`);
              });
          });
        }
      });
    }
  },
};
</script>
<style></style>
